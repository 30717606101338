import React from 'react';

function About() {
    return (
        <section className="about bg-light" id='about'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9">
                        <div className="text-center">
                            <h1 className="about-title">who are the BasedPandos?</h1>
                            <p className="lead text-center">
                                BasedPandos mean Pandas of Superhero who lived on Base Chain, get your Pandos to get many benefit, stake them to get some points, the points are redeemable later.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
