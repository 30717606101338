import React from 'react';
import "../assets/styles.css";
import 'bootstrap/dist/css/bootstrap.css';

function Faq() {
    return (
        <div class="faq container" id="FAQ">
            <h1 class="faqhead text-center">
                Frequently asked questions
            </h1>
            <div class="faq-questions">
                <details open="">
                    <summary>What is BasedPandos?</summary>
                    <div class="faq__content">
                        <p>BasedPandos is a project within the Base blockchain. Based on a collection of NFTs that can be staked to receive BP-XP rewards.</p>
                    </div>
                </details>

                <details>
                    <summary>What wallet can i use?</summary>
                    <div class="faq__content">
                        <p>We currently only support the Metamask wallet.</p>
                    </div>
                </details>
                <details>
                    <summary>What is the price of nft?</summary>
                    <div class="faq__content"><p>The initial price will be 0.0000777 ETH..</p>
                    </div>
                </details>
                <details>
                    <summary>Where can I stake my nft?</summary>
                    <div class="faq__content">
                        <p>You can stake your BasedPandos directly from our app in the mint page where you can als manage your whole wallet of NFTs.</p>
                    </div>
                </details>
            </div>
        </div>
    )
}

export default Faq;
